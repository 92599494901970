<template>
  <form @submit.prevent="handleSave" method="POST">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          
          <div class="col-span-6 sm:col-span-3">
            <label for="title" class="block text-sm font-medium text-gray-700">Naam campagne</label>
            <input type="text" name="title" id="title" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.title">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="slug" class="block text-sm font-medium text-gray-700">Item slug (voor url)</label>
            <input type="text" name="slug" id="slug" autocomplete="slug" class="mt-1 focus:ring-orange-500 bg-gray-200 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.slug" readonly>
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="meta_description" class="block text-sm font-medium text-gray-700">SEO description</label>
            <input type="text" name="meta_description" id="meta_description" autocomplete="meta_description" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.meta_description">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="published" class="block text-sm font-medium text-gray-700">
              Published?
            </label>
            <div class="mt-1 flex">
              <button type="button" :class="c.published ? 'bg-orange-600': 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" role="switch" aria-checked="false" @click="togglePublished">
                <span class="sr-only">Published</span>
                <span aria-hidden="true" :class="c.published ? 'translate-x-5': 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
              </button>
            </div>
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="email_address" class="block text-sm font-medium text-gray-700">Tekst landingspagina</label>
            <TinyMceEditor v-model="c.description" model_name="" :model_id="c.id" :image="true" />
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="hubspot_form_embed_code" class="block text-sm font-medium text-gray-700">hubspot embed code</label>
            <textarea name="hubspot_form_embed_code" id="hubspot_form_embed_code" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.hubspot_form_embed_code"></textarea>
          </div>

        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
          <svg v-show="loading" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
          {{loading ? 'saving': 'save'}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";

import HubspotService from '../../../services/admin/hubspot/service';
import TinyMceEditor from '../generic/TinyMceEditor.vue';

export default {
  name: 'HubspotFormComponent',
  props: ['item'],
  components: {
    TinyMceEditor
  },
  setup(props) {
    const loading = ref(true);
    loading.value = false;

    return {
      c: props.item,
      loading
    }
  },
  methods: {
    togglePublished() {
      this.c.published = !this.c.published;
    },
    async handleSave() {
      this.loading = true;
      HubspotService.save(this.item);
      this.emitter.emit('notification', {type: 'success', message: 'Updated', description: 'The item has been saved succesfully'});
      this.loading = false;
    }
  }
}
</script>
